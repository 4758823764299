.app-info-container {
    .infoSection {
        .textcenteraling {
            margin: 2rem 0 3rem 0;
        }
        h2 {
            font-size: 28px;
            text-align: left;
            color: var(--bs-pc-blue);
            line-height: 40px;
            font-weight: 400;
        }
    }
}

@media (max-width: 600px) {
    .app-info-container {
        .infoSection {
            padding-right: 1rem;
            padding-left: 1rem;
            .mobileview {
                width: 65%;
                margin: 0 auto;
                display: block;
            }
            .textcenteraling {
                margin: 0;
                .app-icons {
                    margin-left: 0.5rem !important;
                }
            }
        }
    }
}
@media (min-width: 1200px) {
    .container {
        &.app-info-container {
            max-width: 992px;
        }
    }
}
