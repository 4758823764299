.message-box {
    padding: 40px 25px 43px;
    background-color: rgba(255, 255, 255, 0.9);
}
.message-box-heading {
    /* width: 188px; */
    /* height: 50px; */
    flex-grow: 0;
    margin: 0 9px 12px;
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    text-align: center;
    color: var(--bs-pc-blue);
}
.message-box-description {
    /* width: 206px; */
    /* height: 76px; */
    flex-grow: 0;
    margin: 12px 0 29px;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.19;
    letter-spacing: normal;
    text-align: center;
    color: #000;
}
.btn-text {
    font-size: 18px;
}
.offer-heading {
    font-size: 28px;
    color: var(--bs-pc-blue);
    font-weight: 400;
}
/* Owl Carousel Start */
.owl-dots {
    display: none;
}
.position-absolute {
    position: absolute !important;
    right: 15%;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 250px;
    height: 210px;
}
.owl-carousel-asset {
    aspect-ratio: 2.239;
    overflow: hidden;
    width: -webkit-fill-available;
}
/* Owl Carousel End */
@media only screen and (max-width: 600px) {
    .message-box {
        top: 5%;
        bottom: 5%;
        left: 20px;
        right: 20px;
        margin: 22px;
        display: table;
        padding: 20px;
        position: absolute;
        margin: 10px auto;
        height: 37vh;
    }
    .owl-carousel .owl-item video,
    .owl-carousel .owl-item img {
        display: block;
        width: 100%;
        height: 45vh;
        object-fit: cover;
    }
    .message-box-heading {
        width: auto;
        height: 50px;
        flex-grow: 0;
        margin: 0 9px 12px;
        font-size: 20px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.25;
        letter-spacing: normal;
        text-align: center;
        color: var(--bs-pc-blue);
    }
    .message-box-description {
        width: auto;
        height: 76px;
        flex-grow: 0;
        margin: 12px 0 29px;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.19;
        letter-spacing: normal;
        text-align: center;
        color: #000;
    }
}
