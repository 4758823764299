#hero-section {
    .banner-img-aspect {
        aspect-ratio: 2.5;
    }
    .membership-banner-image {
        height: auto;
        object-fit: cover;
        width: 100%;
    }
    &.knowMore {
        position: relative;
        top: 0;
        left: 0;
        bottom: 0;
        width: 100%;
    }
    .know-more-form-container {
        .knowMoreFormBlock {
            .knowMoreInnerBlock {
                background: #fff;
                padding: 23px 46px;
                max-width: 456px;
                // float: right;
                position: absolute;
                right: 14%;
                top: 0;
                bottom: 0;
                margin: auto;
                height: 540px;
                #knowMoreForm {
                    h2 {
                        font-size: 26px;
                        color: #000;
                        font-weight: 400;
                    }
                    [type="text"],
                    [type="tel"],
                    [type="email"],
                    select {
                        border: none;
                        border-radius: 0px;
                        padding: 5px 0px;
                        border-bottom: 1px solid #999;
                        margin: 7px 0px;
                        color: rgba(33, 37, 41);
                    }
                    .button {
                        margin: 21px 14px 0 0;
                        padding: 11px 62px 14px 63px;
                        border-radius: 5px;
                        background-color: var(--bs-pc-blue);
                        border: none;
                        text-align: center;
                    }
                    .form-check {
                        margin-top: 0.7rem;
                        input[type="checkbox"] {
                            &:checked {
                                background-color: var(--bs-pc-blue);
                            }
                            width: 1.2em;
                            height: 1.2em;
                            margin-top: 0.25em;
                        }
                        label {
                            color: rgba(82, 82, 82, 0.75);
                            font-size: 12px;
                        }
                    }
                    .knowmore-country-select {
                        .country-select-button {
                            color: rgba(33, 37, 41, 0.75);
                            background-color: #fff;
                            border: none;
                            border-radius: 0px;
                            padding: 5px 0px;
                            border-bottom: 1px solid #999;
                            margin: 7px 0px;
                        }
                        .country-select-down-arrow {
                            width: 16px;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 1024px) {
    #hero-section {
        &.knowMore {
            .membership-banner-image {
                height: 570px;
            }
        }
    }
}

@media (max-width: 900px) {
    #hero-section {
        &.knowMore {
            object-fit: cover;
            margin-bottom: 30px;
            .knowMore-img {
                width: 100%;
            }
        }
    }
}

@media (max-width: 768px) {
    #hero-section {
        .know-more-form-container {
            .knowMoreFormBlock {
                .knowMoreInnerBlock {
                    width: 100%;
                    height: 600px;
                }
            }
        }
    }
}

@media (max-width: 600px) {
    #hero-section {
        &.knowMore {
            height: 100%;
            margin-bottom: 90%;
            .membership-banner-image {
                height: auto;
            }
        }
        .know-more-form-container {
            .knowMoreFormBlock {
                width: 100%;
                margin: 0 auto;
                .knowMoreInnerBlock {
                    box-shadow: 0px 2px 13px 0px rgba(0, 0, 0, 0.13);
                    padding: 30px 30px;
                    top: 85%;
                    left: 0;
                    right: 0;
                    margin: 0 auto;
                    width: 95%;
                    #knowMoreForm {
                        h2 {
                            text-align: center;
                        }
                        .button {
                            justify-content: center;
                            display: flex;
                            width: 49%;
                            margin: 25px auto;
                            padding: 11px;
                        }
                    }
                }
            }
        }
    }
}
