.terms-section-container {
    padding-top: 50px;
    margin-bottom: 150px;
    h1 {
        color: var(--bs-pc-blue);
        font-size: 32px;
        font-weight: 400;
    }
    .term-point {
        margin-top: 50px;
        .term-point-sub-data {
            h6 {
                margin-top: 15px;
                font-weight: 600;
                font-size: 17px;
                color: #666666;
            }
        }
        .term-point-sub-data-child {
            h6 {
                margin-top: 15px;
                font-weight: 600;
                font-size: 16px;
                color: #666666;
            }
        }
        h5 {
            color: black;
            font-size: 18px;
            font-weight: 700;
        }
        ul,
        ol {
            li {
                text-align: justify;
                color: rgba(102, 102, 102, 1);
                font-weight: 400;
                font-size: 16px;
                margin-top: 10px;
            }
        }
    }
}

@media (max-width: 370px) {
    .terms-section-container {
        h1 {
            font-size: 28px;
        }
    }
}
@media (max-width: 500px) {
    .terms-section-container {
        .term-point {
            .term-point-sub-data-child {
                .sub-child-ul {
                    padding-left: 1rem;
                    ol {
                        padding-left: 1rem;
                    }
                }
            }
        }
    }
}
