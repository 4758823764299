.redeem-benefit-section {
    margin-top: 4rem;
    .redeem-benefit-container {
        &.container {
            width: 70%;
            display: flex;
            flex-direction: column;
            align-items: center;
            .heading-detail {
                text-align: center;
                color: rgba(85, 85, 85, 1);
                font-size: 19px;
                font-weight: 400;
            }
            .otp-inputs-wrapper {
                width: auto;
                margin-top: 2rem;
                .otp-inputs {
                    justify-content: center;
                    input {
                        color: rgba(85, 85, 85, 1);
                        font-size: 20px;
                        border: none;
                        border-bottom: 1px solid #999;
                        outline: 0;
                        width: 50px !important;
                        height: 40px !important;
                    }
                }
                .error-text {
                    font-size: 14px;
                    margin-bottom: 3rem;
                    color: red;
                    text-align: left;
                }
            }
            button {
                &:active {
                    background-color: var(--bs-pc-blue);
                }
                width: 8rem;
                padding: 8px 10px;
            }
        }
    }
}
@media only screen and (max-width: 768px) {
    #invalid-transactionId,
    #success-otp-modal {
        .modal-content {
            width: 90%;
        }
    }
    .redeem-benefit-section {
        .redeem-benefit-container {
            &.container {
                width: 95%;
            }
        }
    }
}
