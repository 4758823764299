.buy-membership-container {
    display: flex;
    gap: 5rem;
    padding: 3rem;
    hr {
        margin-top: 3rem;
        margin-bottom: 3rem;
    }
    .membership-steps-section {
        max-width: 33%;
        .list-wrapper {
            position: relative;
        }
        .list-item-wrapper {
            position: relative;
            &.active {
                .list-bullet {
                    background: var(--bs-pc-blue);
                    + .list-item {
                        .list-title {
                            color: var(--bs-pc-blue);
                        }
                    }
                }
            }
        }
        .list-bullet {
            float: left;
            margin-right: 20px;
            margin-top: 18px;
            height: 16px;
            width: 16px;
            border-radius: 100px;
            color: white;
            border: 1px solid var(--bs-pc-blue);
            background: white;
        }
        .list-item {
            display: table-row;
            vertical-align: middle;
        }
        .list-title {
            line-height: 50px;
            font-size: 24px;
            color: #ababab;
            font-weight: 400;
        }
        .red-line {
            background: var(--bs-pc-blue);
            z-index: -1;
            width: 1.5px;
            margin-top: 20px;
            height: 72%;
            position: absolute;
            left: 7px;
        }
    }
    .membership-steps-section-mobile {
        display: none;
        width: 100%;
        margin: 0 auto;
        .step {
            display: table-cell;
            position: relative;
            padding: 15px;
            width: 35%;
            &.active {
                .step-circle {
                    background: var(--bs-pc-blue);
                }
                .step-title {
                    color: var(--bs-pc-blue);
                }
            }
            &:first-child {
                .step-bar-left {
                    display: none;
                }
            }
            &:last-child {
                .step-bar-right {
                    display: none;
                }
            }
            .step-circle {
                border: 1px solid var(--bs-pc-blue);
                width: 20px;
                height: 20px;
                margin: 8px auto;
                border-radius: 50%;
            }
            .step-title {
                color: #989898;
                margin-top: 16px;
                font-size: 15px;
                font-weight: 400;
                text-align: center;
            }
            .step-bar-right {
                position: absolute;
                top: 36px;
                height: 1px;
                border-top: 1px solid #dddddd;
                right: 0;
                left: 50%;
                margin-left: 20px;
            }
            .step-bar-left {
                position: absolute;
                top: 36px;
                height: 1px;
                border-top: 1px solid #dddddd;
                left: 0;
                right: 50%;
                margin-right: 20px;
            }
        }
    }
    .membership-detail-section {
        .row-level-margin {
            margin-top: 2rem;
        }
        .membership-text {
            font-size: 16px;
            font-weight: 300;
            color: #777777;
        }
        .membership-sub-text {
            font-size: 16px;
            font-weight: 400;
        }
        h1 {
            font-size: 24px;
            font-weight: 400;
        }
        input {
            width: 100%;
            border: 1px solid var(--bs-pc-blue);
            color: rgba(33, 37, 41);
            padding: 10px;
            &:focus {
                outline: none;
                border-bottom: 1px solid var(--bs-pc-blue) !important;
            }
            &::placeholder {
                color: rgba(33, 37, 41, 0.75);
            }
        }
        .cross-btn {
            cursor: pointer;
            position: absolute;
            right: 35px;
            top: 18px;
        }
        button {
            font-size: 18px;
            width: 100%;
            padding: 10px;
            border: 0px;
            color: #ffff;
            background-color: var(--bs-pc-blue);
            &:focus {
                outline: none;
            }
            &:disabled {
                background-color: #aaaaaa;
            }
        }
        .success-test {
            font-size: 14px;
            font-weight: 400;
            color: var(--bs-pc-blue);
        }
        .fail-text {
            font-size: 14px;
            font-weight: 400;
            color: red;
        }
    }
    .membership-price-section {
        .price-text {
            font-size: 16px;
            font-weight: 400;
            &.total-text {
                text-align: end;
            }
        }
        .price-sub-text {
            font-size: 16px;
            font-weight: 700;
            &.total-price {
                font-size: 22px;
                text-align: end;
            }
        }
    }
    .membership-user-info-section {
        h2 {
            font-weight: 400;
            font-size: 24px;
        }
        input {
            border: none;
            border-radius: 0px;
            padding: 5px 0px;
            border-bottom: 1px solid #999;
            margin-top: 7px;
            color: rgba(33, 37, 41);
        }
        select {
            border: none;
            border-radius: 0px;
            padding: 5px 0px;
            border-bottom: 1px solid #999;
            margin: 7px 0px;
            color: rgba(33, 37, 41);
        }
        input:focus,
        textarea:focus,
        select:focus {
            outline: none;
        }
        .membership-country-select {
            position: relative;
            .country-select-button {
                color: rgba(82, 82, 82, 0.75);
                background-color: #fff;
                border: none;
                border-radius: 0px;
                padding: 5px 0px;
                border-bottom: 1px solid #999;
                margin: 7px 0px;
            }
            .country-select-down-arrow {
                width: 16px;
            }
        }
        .invalid-otp {
            input,
            input:focus {
                border-bottom: 1px solid red !important;
            }
        }
        .phone-row {
            margin-top: 0.5rem;
        }
        .col-otp-div {
            .otp-div {
                display: flex;
                .otp-label {
                    display: flex;
                    align-items: center;
                    margin-top: 7px;
                }
                .otp-input-div {
                    padding-left: 8%;
                    .otp-plugin {
                        input,
                        input:focus {
                            outline: 0;
                            flex-grow: 1;
                            height: 36px !important;
                        }
                    }
                }
                img {
                    margin-top: 7px;
                }
            }
            .resend-otp {
                margin-top: 0.5rem;
                display: flex;
                align-items: center;
                width: 100%;
                .placeholder-div {
                    padding-right: 1.5rem;
                }
                .timer-div {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    width: 54%;
                    p {
                        margin-bottom: 0;
                    }
                }
                .disable {
                    font-size: 16px;
                    color: grey;
                    font-weight: 500;
                    cursor: not-allowed;
                }
                .text {
                    font-size: 16px;
                    color: var(--bs-pc-blue);
                    font-weight: 500;
                }
                .timer {
                    font-size: 14px;
                    color: var(--bs-pc-blue);
                    font-weight: 500;
                }
            }
        }
    }
    .membership-user-gst-section {
        margin-top: 3rem;
        h2 {
            font-weight: 400;
            font-size: 24px;
        }
        input:not([type="checkbox"]) {
            font-weight: 400;
            font-size: 16px;
            border: none;
            border-radius: 0px;
            padding: 5px 0px;
            border-bottom: 1px solid #999;
            margin-top: 7px;
            &::placeholder {
                color: rgba(33, 37, 41, 0.75);
                font-weight: 400;
                font-size: 16px;
            }
        }
        input[type="checkbox"] {
            border: 1px solid var(--bs-pc-blue);
            + label {
                color: #535353;
                font-weight: 300;
                font-size: 14px;
            }
        }
        select {
            border: none;
            border-radius: 0px;
            padding: 5px 0px;
            border-bottom: 1px solid #999;
            margin: 7px 0px;
            color: rgba(82, 82, 82, 0.75);
            &:disabled {
                background-color: transparent;
            }
        }
        input:focus,
        textarea:focus,
        select:focus {
            outline: none;
        }
        .row {
            margin-top: 0.7rem;
        }
        .row:nth-child(2) {
            margin-top: 1.5rem;
        }
        .step-1-btn-grp {
            margin-top: 55px;
            width: 50%;
            display: flex;
            gap: 2rem;
            justify-content: space-around;
            button {
                border: 1px solid var(--bs-pc-blue);
                width: 100%;
                font-size: 18px;
                font-weight: 400;
                padding: 8px;
            }
            .back-btn {
                color: var(--bs-pc-blue);
                background-color: transparent;
            }
            .next-btn {
                color: #ffffff;
                background-color: var(--bs-pc-blue);
            }
        }
    }
    .confirm-membership-details {
        .col-6:has(.confirm-membership-sub-text) {
            padding-left: 0;
        }
        h1 {
            font-size: 24px;
            font-weight: 400;
        }
        .confirm-membership-text {
            font-size: 16px;
            font-weight: 300;
            color: #5e5e5e;
        }
        .confirm-membership-sub-text {
            font-size: 16px;
            font-weight: 400;
            color: #000000;
        }
        .row:nth-child(1) {
            margin-bottom: 2rem;
        }
        .row:not(:nth-child(1)) {
            margin-top: 1rem;
        }
    }
    .confirm-personal-details {
        margin-top: 4rem;
        .col-6:has(.confirm-personal-sub-text) {
            padding-left: 0;
        }
        h2 {
            font-size: 24px;
            font-weight: 400;
        }
        .confirm-personal-text {
            font-size: 16px;
            font-weight: 300;
            color: #5e5e5e;
        }
        .confirm-personal-sub-text {
            font-size: 16px;
            font-weight: 400;
            color: #000000;
            word-wrap: break-word;
        }
        .row:nth-child(1) {
            margin-bottom: 2rem;
        }
        .row:not(:nth-child(1)) {
            margin-top: 1rem;
        }
        .edit-btn {
            font-size: 16px;
            font-weight: 400;
            color: var(--bs-pc-blue);
            text-align: end;
        }
    }
    .confirm-gst-details {
        margin-top: 4rem;
        .col-6:has(.confirm-gst-sub-text) {
            padding-left: 0;
        }
        h2 {
            font-size: 24px;
            font-weight: 400;
        }
        .confirm-gst-text {
            font-size: 16px;
            font-weight: 300;
            color: #5e5e5e;
        }
        .confirm-gst-sub-text {
            font-size: 16px;
            font-weight: 400;
            color: #000000;
            word-wrap: break-word;
        }
        .row:nth-child(1) {
            margin-bottom: 2rem;
        }
        .row:not(:nth-child(1)) {
            margin-top: 1rem;
        }
        .edit-btn {
            font-size: 16px;
            font-weight: 400;
            color: var(--bs-pc-blue);
            text-align: end;
        }
    }
    .confirm-details-btn-group {
        margin-top: 4rem;
        input[type="checkbox"] {
            border: 1px solid var(--bs-pc-blue);
            + label {
                color: #535353;
                font-weight: 300;
                font-size: 12px;
            }
        }
        .step-2-btn-grp {
            margin-top: 55px;
            width: 50%;
            display: flex;
            gap: 2rem;
            justify-content: space-around;
            &.payment-link-btn {
                display: block;
                button {
                    width: auto;
                    padding: 7px 25px 7px 25px;
                }
            }
            button {
                border: 1px solid var(--bs-pc-blue);
                width: 100%;
                font-size: 18px;
                font-weight: 400;
                padding: 8px;
            }
            .back-btn {
                color: var(--bs-pc-blue);
                background-color: transparent;
            }
            .next-btn {
                color: #ffffff;
                background-color: var(--bs-pc-blue);
                &:disabled {
                    background-color: #aaaaaa;
                    border-color: #aaaaaa;
                }
            }
        }
    }
    .link-based-gst-section {
        margin-top: 4rem;
        .add-gst-btn {
            background-color: transparent;
            border: 1px solid var(--bs-pc-blue);
            font-size: 18px;
            padding: 7px 25px 7px 25px;
            color: #000000;
            border-radius: 5px;
        }
    }
}
@media only screen and (max-width: 768px) {
    .buy-membership-container {
        flex-direction: column;
        gap: 2rem;
        padding: 1rem 1.5rem;
        hr {
            margin-top: 2rem;
            margin-bottom: 2rem;
        }
        .membership-detail-section {
            .responsive-margin {
                margin-top: 1rem;
            }
            .membership-text {
                font-size: 15px;
            }
            .membership-sub-text {
                font-size: 15px;
            }
            input {
                font-size: 15px;
            }
        }
        .membership-steps-section {
            display: none;
        }
        .membership-steps-section-mobile {
            display: table;
        }
        .membership-price-section {
            .price-text {
                color: #777777;
                font-size: 15px;
                &.total-text {
                    text-align: start;
                    color: #000000;
                }
            }
            .price-sub-text {
                font-size: 15px;
                font-weight: 400;
                &.total-price {
                    font-weight: 700;
                    font-size: 15px;
                    text-align: start;
                }
            }
        }
        .membership-user-gst-section {
            .row,
            .mobile-row {
                margin-top: 0.5rem;
            }
            .step-1-btn-grp {
                margin-top: 45px;
                width: 100%;
            }
        }
        .confirm-membership-details {
            h1 {
                font-size: 20px;
            }
            .confirm-membership-text,
            .confirm-membership-sub-text {
                font-size: 14px;
            }
            .row:nth-child(1) {
                margin-bottom: 1rem;
            }
        }
        .confirm-personal-details {
            h2 {
                font-size: 20px;
            }
            .confirm-personal-text {
                font-size: 14px;
            }
            .confirm-personal-sub-text {
                font-size: 14px;
            }
        }
        .confirm-gst-details {
            h2 {
                font-size: 20px;
            }
            .confirm-gst-text {
                font-size: 14px;
            }
            .confirm-gst-sub-text {
                font-size: 14px;
            }
        }
        .confirm-details-btn-group {
            .step-2-btn-grp {
                width: 100%;
            }
        }
    }
}
@media only screen and (max-width: 380px) {
    .buy-membership-container {
        .confirm-details-btn-group {
            .step-2-btn-grp {
                .back-btn,
                .next-btn {
                    font-size: 14px;
                }
            }
        }
    }
}
