.participating-hotel-section {
    margin-bottom: 7rem;
    .participating-hotel-heading {
        font-size: 28px;
        font-weight: 400;
        color: var(--bs-pc-blue);
        margin-bottom: 4rem;
    }
    .participating-hotel-slider-container {
        .first-element-padding {
            .slick-list {
                padding: 0px !important;
            }
        }
        .slick-list {
            transition: all ease 500ms;
        }
        .hide-arrow {
            display: none;
        }
        .show-arrow {
            width: auto;
            height: auto;
        }
        .participating-hotel-card {
            margin: 0 1rem 1rem 1rem;
            box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
            .participating-hotel-card-heading {
                font-size: 22px;
                font-weight: 400;
                color: var(--bs-pc-blue);
            }
        }
    }
}

@media only screen and (max-width: 480px) {
    .participating-hotel-section {
        .participating-hotel-slider-container {
            .slick-list {
                padding: 0px 60px !important;
            }
            .participating-hotel-card {
                .participating-hotel-card-heading {
                    font-size: 18px;
                }
            }
        }
    }
}
