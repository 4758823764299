.membership-privilege-section {
    .membership-privilege-heading {
        font-size: 28px;
        color: var(--bs-pc-blue);
        font-weight: 400;
        margin-bottom: 1.5rem;
    }
    .membership-privilege-heading-subText {
        font-size: 20px;
        font-weight: 400;
        color: #555555;
        margin-bottom: 3.5rem;
        line-height: 27px;
    }
    .membership-privilege-slider-container {
        .first-element-padding {
            .slick-list {
                padding: 0px !important;
            }
        }
        .slick-list {
            transition: all ease 500ms;
        }
        .membership-privilege-tile-card {
            margin-left: 1rem;
            margin-right: 1rem;
            .membership-privilege-tile-heading {
                font-size: 24px;
                font-weight: 400;
                color: var(--bs-pc-blue);
            }
            .membership-privilege-tile-subText {
                font-size: 16px;
                font-weight: 400;
                color: #555555;
                line-height: 19px;
            }
        }
    }
}

@media only screen and (max-width: 480px) {
    .membership-privilege-section {
        .membership-privilege-slider-container {
            .slick-list {
                padding: 0px 60px !important;
            }
        }
    }
}
