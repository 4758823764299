#custom-nav {
    z-index: 1040;
    background-color: white;
    .container {
        .navbar-toggler {
            border: none;
            &:focus {
                box-shadow: none;
            }
            .navbar-toggler-icon {
                --bs-navbar-toggler-icon-bg: url("../../assets/hamburger-mob.svg");
            }
            &.mobile-show {
                .navbar-toggler-icon {
                    --bs-navbar-toggler-icon-bg: url("../../assets/close-btn.svg");
                    width: 1em;
                }
            }
        }
        .navbar-nav {
            .nav-item {
                .dropdown-menu {
                    .dropdown-item {
                        &:hover {
                            background-color: transparent;
                        }
                    }
                    hr {
                        margin: 0;
                        opacity: 0.1;
                    }
                }
                .dropdown-toggle {
                    &::after {
                        width: 16px;
                        height: 10px;
                        background-image: url("../../assets//dropdown.png");
                        background-size: contain;
                        background-repeat: no-repeat;
                        border: 0;
                        vertical-align: middle;
                    }
                    &.show {
                        &::after {
                            transform: rotate(180deg);
                        }
                    }
                }
            }
            .nav-link {
                font-size: 16px;
                color: rgb(0, 0, 0);
            }
        }
    }
}
#custom-nav-mobile {
    width: fit-content;
    max-width: 90%;
    height: fit-content;
    box-shadow: 0px 2px 13px 0px rgba(0, 0, 0, 0.13);
    left: auto;
    &.offcanvas-toggling {
        z-index: 1039;
    }
    .offcanvas-body {
        padding-left: 0;
        padding-right: 0;
        .nav {
            display: block;
            .nav-link {
                font-size: 16px;
                color: rgba(51, 51, 51, 1);
            }
        }
    }
}
.offcanvas-backdrop {
    &.custom-nav-mobile-backdrop {
        top: var(--header-height);
        z-index: 1038;
    }
}

@media (max-width: 992px) {
    #custom-nav {
        .container {
            .navbar-brand {
                img {
                    width: 75%;
                }
            }
        }
    }
}
