/* ContactUsLinks START */
.contactus h1 {
    text-align: center;
    font-size: 32px;
    margin: 40px 0px 20px 0px;
    font-weight: 400;
}
.contactus p {
    text-align: center;
    font-size: 23px;
}
.social-icon {
    text-align: center;
    display: block;
    margin: 0 auto;
    margin-top: 30px;
}
.social-icon a {
    padding: 20px;
}
.contactus button {
    display: block;
    text-align: center;
    margin: 0 auto;
    margin-top: 50px;
    margin-bottom: 50px;
    padding: 15px 50px;
}
.contactForm button {
    display: block;
    text-align: center;
    margin: 0 auto;
}
/* ContactUsLinks END */
/* ContactUsForm START */
.contactForm [type="text"],
.contactForm select,
.contactForm [type="tel"],
.contactForm [type="email"] {
    border: none;
    border-radius: 0px;
    padding: 5px 0px;
    border-bottom: 1px solid #999;
    margin: 10px 0px;
    color: rgba(33, 37, 41);
}
.contactForm::-webkit-input-placeholder {
    color: rgba(33, 37, 41, 0.75);
}

input:focus,
textarea:focus,
select:focus {
    box-shadow: none !important;
    border-bottom: 1px solid #999 !important;
}
.contactForm {
    background: #fff;
    box-shadow: 0px 1px 11px 0px rgba(0, 0, 0, 0.08);
    padding: 100px;
}
.contactForm textarea {
    resize: none;
    border: 1px solid #999;
}
.addressbook a {
    text-decoration: none;
}
@media (max-width: 600px) {
    .contactForm {
        background: #fff;
        box-shadow: 0px 1px 11px 0px rgba(0, 0, 0, 0.08);
        padding: 21px;
    }
}
/* ContactUsForm END */
