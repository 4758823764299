#add-gst-modal {
    .modal-content {
        .modal-header {
            border: none;
        }
        .modal-body {
            padding: 0 40px 0 40px;
            .gst-modal-heading {
                font-weight: 400;
                font-size: 24px;
            }
            input:not([type="checkbox"]) {
                font-weight: 400;
                font-size: 16px;
                border: none;
                border-radius: 0px;
                padding: 5px 0px;
                border-bottom: 1px solid #999;
                margin-top: 7px;
                color: rgba(33, 37, 41);
                &::placeholder {
                    color: rgba(33, 37, 41, 0.75);
                    font-weight: 400;
                    font-size: 16px;
                }
            }
            select {
                border: none;
                border-radius: 0px;
                padding: 5px 0px;
                border-bottom: 1px solid #999;
                margin: 7px 0px;
                color: rgba(33, 37, 41, 0.75);
                &:disabled {
                    background-color: transparent;
                }
            }
            input:focus,
            textarea:focus,
            select:focus {
                outline: none;
            }
            .row {
                margin-top: 0.7rem;
            }
            .row:nth-child(2) {
                margin-top: 1.5rem;
            }
        }
        .modal-footer {
            border: none;
            justify-content: center;
            padding-bottom: 30px;
            button {
                background-color: var(--bs-pc-blue);
                border: none;
                font-size: 16px;
                padding: 7px 25px 7px 25px;
                color: #fff;
                border-radius: 5px;
            }
        }
    }
}
