.membership-price {
    padding: 50px 75px 50px 75px;
    box-sizing: border-box;
    margin-bottom: 30px;
    box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.1);
    background-color: #fff;
    text-align: center;
}
.membership-price h3 {
    text-align: center;
    font-size: 22px;
    color: #6b6b6b;
    margin-bottom: 50px;
    font-weight: 400;
}
.innerSection {
    padding: 35px 15px 35px 15px;
    border-radius: 10px;
    border: solid 2px #ececec;
}
.innerSection p {
    margin: 0px;
}
.innerSection h4 {
    font-size: 18px;
    color: #555;
    margin: 12px 0px 0px 0px;
    font-weight: 400;
}
.innerSection p {
    font-size: 24px;
    color: #555;
    font-weight: 600;
}
.innerSection span {
    font-size: 14px;
    color: #555;
}
.innerSection.selected {
    border: solid 2px var(--bs-pc-blue);
}
.membership-price-button {
    cursor: pointer;
    margin: 58px 112px 0 116px;
    padding: 8px 45px 8px 45px;
    border-radius: 5px;
    border: solid 1px var(--bs-pc-blue);
    background-color: var(--bs-pc-blue);
    color: white;
    font-size: 18px;
}
.contact-channel {
    margin-top: 40px;
}
.contact-channel img {
    padding: 20px;
    box-sizing: border-box;
}
@media (max-width: 900px) {
    .innerSection {
        margin-bottom: 30px;
    }
}
@media (max-width: 600px) {
    .membership-price {
        padding: 14px;
        box-shadow: none;
    }
    .innerSection {
        padding: 25px 15px 25px 15px;
        border-radius: 10px;
        border: solid 2px #ececec;
    }
    .innerSection h4 {
        color: #555;
        font-size: 18px;
        font-weight: 400;
        margin: 22px 0 3px;
    }
    .contact-channel {
        margin-top: 0px;
        border-bottom: 1px solid #c7c7c7;
        padding-bottom: 30px;
    }
}
@media only screen and (max-width: 480px) {
    .membership-price-button {
        margin-left: auto;
        margin-right: auto;
        margin-top: 35px;
        padding: 8px 45px 8px 45px;
        font-size: 16px;
    }
}
