.contact-us-country-select {
    padding-top: 0.2rem;
}
.contact-us-country-select .country-select-button {
    color: rgba(82, 82, 82, 0.75);
    background-color: #fff;
    border: none;
    border-radius: 0px;
    padding: 5px 0px;
    border-bottom: 1px solid #999;
    margin: 7px 0px;
}
.contact-us-country-select .country-select-down-arrow {
    width: 16px;
}
.contactForm .btn {
    /* Patch-work */
    width: 189px;
    height: 48px;
}
