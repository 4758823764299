.loading-backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    z-index: 9999;
    height: 100vh;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.25);
    overflow: hidden;
    .loading {
        display: inline-block;
        position: relative;
        width: 64px;
        height: 64px;
        div {
            position: absolute;
            background: var(--bs-pc-blue);
            opacity: 1;
            border-radius: 50%;
            animation: loading 1.4s cubic-bezier(0, 0.2, 0.8, 1) infinite;
        }
        div:nth-child(2) {
            animation-delay: -0.7s;
        }
    }
}
@keyframes loading {
    0% {
        top: 28px;
        left: 28px;
        width: 0;
        height: 0;
        opacity: 1;
    }
    100% {
        top: -1px;
        left: -1px;
        width: 58px;
        height: 58px;
        opacity: 0;
    }
}
#payment-loader-modal {
    .modal-content {
        padding: 20px 60px;
        .modal-body {
            text-align: center;
            img {
                width: 20%;
            }
            p {
                margin-top: 20px;
                font-size: 16px;
            }
        }
    }
}
@media (max-width: 600px) {
    #payment-loader-modal {
        --bs-modal-margin: 3rem;
        .modal-content {
            padding: 20px 10px;
            .modal-body {
                img {
                    width: 20%;
                }
                p {
                    font-size: 15px;
                }
            }
        }
    }
}
