.overview h1 {
    color: var(--bs-pc-blue);
    margin: 50px 0px 30px 0px;
    font-weight: 400;
}
.overview .btn.btn-primary {
    background-color: var(--bs-pc-blue);
    padding: 10px 60px;
    margin-top: 50px;
}
