.payment-message-section {
    .payment-box {
        padding: 50px 75px 50px 75px;
        box-sizing: border-box;
        box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.1);
        background-color: #fff;
        text-align: center;
        width: 80%;
        margin: 40px auto 40px auto;
        .payment-text {
            h1 {
                font-size: 26px;
                font-weight: 400;
                color: #000;
                margin: 20px 0 20px 0;
            }
            p {
                text-align: left;
                font-size: 16px;
                font-weight: 400;
                color: #525252;
                padding-bottom: 10px;
            }
            .home-btn {
                background-color: var(--bs-pc-blue);
                border: none;
                font-size: 18px;
                padding: 7px 25px 7px 25px;
                color: #fff;
                border-radius: 5px;
            }
        }
    }
}
@media (max-width: 768px) {
    .payment-message-section .payment-box {
        width: 100%;
        padding: 40px 10px 40px 10px;
    }
}
