.hotel-info-container {
    .infoSection {
        margin-top: 100px;
        .textcenteraling {
            h1 {
                font-size: 28px;
                text-align: left;
                color: var(--bs-pc-blue);
                line-height: 40px;
                font-weight: 400;
            }
            p {
                font-size: 16px;
                margin: 25px 20px 25px 0px;
                text-align: justify;
                a{
                    text-decoration: none;
                    color: black;
                }
            }
        }
    }
}

@media (min-width: 1200px) {
    .container {
        &.hotel-info-container {
            max-width: 992px;
        }
    }
}
@media (max-width: 768px) {
    .hotel-info-container {
        .infoSection {
            margin-top: 60px;
            margin-left: 1rem;
            margin-right: 1rem;
            .textcenteraling {
                p {
                    margin: 25px 0px;
                }
            }
        }
    }
}
