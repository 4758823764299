* {
    font-family: "Gotham";
    --bs-pc-blue: #2c4390;
}
body {
    font-family: "Gotham";
}
.my-5rem {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
}
.mt-5rem {
    margin-top: 5rem !important;
}
.mb-5rem {
    margin-bottom: 5rem !important;
}
.cp {
    cursor: pointer;
}
.cd {
    cursor: not-allowed;
}
.invalid-captcha iframe {
    border: 1px solid red !important;
}
.border-blue {
    border-color: var(--bs-pc-blue) !important;
}
.download-app {
    width: 142px;
}
.embedded-service .channelmenu_header > h2 {
    font-size: 0.875em !important;
    font-weight: normal !important;
    letter-spacing: 0.04em;
    text-transform: none;
    margin: 0 0 8px 0;
    font-family: "Gotham" !important;
}
.embedded-service .channelmenu_header > p {
    font-size: 0.875em;
    letter-spacing: 0.0285em;
    margin: 0;
    font-family: "Gotham" !important;
}
.invalid {
    border: 1px solid red !important;
}
.invalid-bottom {
    border-bottom: 1px solid red !important;
}
.invalid:focus {
    border: 1px solid red !important;
}
.invalid-bottom:focus {
    border-bottom: 1px solid red !important;
}
.select-placeholder {
    color: rgba(33, 37, 41, 0.75) !important;
}
.btn-primary,
.btn-primary:hover {
    background-color: var(--bs-pc-blue);
    border: none;
}
.form-select {
    --bs-form-select-bg-img: url("./assets/dropdown.png");
    background-repeat: no-repeat;
    background-position: right 0.9rem;
    background-size: 15px 9px;
}
.form-check-input {
    width: 1.2em;
    height: 1.2em;
    &:checked {
        background-color: #2c4390;
    }
    &.is-valid {
        &:checked {
            background-color: #2c4390;
        }
        ~ .form-check-label {
            color: rgba(82, 82, 82, 0.75);
            font-size: 12px;
        }
    }
}
@media (min-width: 1200px) {
    .homepage-container {
        max-width: 940px;
    }
}
