.custom-common-modal {
    .modal-dialog {
        display: flex;
        justify-content: center;
        .modal-content {
            width: 70%;
            .modal-header {
                border: none;
            }
            .modal-body {
                text-align: center;
                padding-top: 30px;
                padding-bottom: 0;
                .custom-modal-content {
                    font-size: 16px;
                    padding-top: 20px;
                }
            }
            .modal-footer {
                border: none;
                justify-content: center;
                padding-bottom: 30px;
                button {
                    background-color: var(--bs-pc-blue);
                    border: none;
                    font-size: 16px;
                    padding: 7px 25px 7px 25px;
                    color: #fff;
                    border-radius: 5px;
                }
            }
        }
    }
}
