.member-benefit-heading {
    font-size: 28px;
    color: var(--bs-pc-blue);
    font-weight: 400;
}
.member-benefit-sub-heading {
    color: #6b6b6b;
    font-size: 22px;
    font-weight: 400;
}
