.infoSection {
    margin-top: 70px;
}
.thankupage {
    text-align: center;
    margin: 0 auto;
}
.thankupage img {
    margin: 20px 0px;
}
.thankyoutext h3 {
    font-size: 26px;
    color: #000;
    text-align: center;
}
.thankyoutext p {
    color: rgba(82, 82, 82, 0.75);
    text-align: center;
    font-size: 16px;
    margin-bottom: 50px;
}
.homebtn {
    background-color: var(--bs-pc-blue) !important;
    border: none !important;
    font-size: 18px;
    padding: 7px 25px 7px 25px;
    color: #fff;
    border-radius: 5px;
}
.homebtn a {
    color: #fff;
    text-decoration: none;
}
.thankyouTick svg {
    margin: 15px;
}
