footer {
    border-top: 1px solid #c7c7c7;
    padding: 50px 0;
    .page-footer-container {
        .footer-link-section {
            p {
                font-size: 16px;
                font-weight: 400;
                color: #000;
                margin-top: 0.5rem;
                margin-bottom: 0;
            }
            a {
                text-decoration: none;
            }
        }
        .footer-detail-section {
            text-align: end;
            p {
                color: #595959;
                font-size: 14px;
            }
            a {
                text-decoration: none;
                color: var(--bs-pc-blue);
            }
        }
    }
}
@media (max-width: 750px) {
    footer {
        .page-footer-container {
            padding-left: 1.5rem;
            padding-right: 1.5rem;
            .footer-detail-section {
                margin-top: 2rem;
            }
            .footer-detail-section {
                text-align: start;
            }
        }
    }
}

@media (max-width: 480px) {
    footer {
        .page-footer-container {
            .footer-detail-section {
                &.middle-mobile {
                    padding: 0;
                }
            }
        }
    }
}
