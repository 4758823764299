.cookie-consent-modal {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 1.5rem 4rem;
    z-index: 1050;
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-color: var(--bs-body-bg);
    box-shadow: 3px -3px 16.4px -4px rgba(0, 0, 0, 0.2);
    .cookie-consent {
        width: 80%;
        .cookie-consent-heading {
            font-size: 20px;
            color: var(--bs-pc-blue);
        }
        .cookie-consent-content {
            font-size: 14px;
            margin-bottom: 0;
            a {
                color: var(--bs-pc-blue);
                text-decoration: none;
            }
        }
    }
    .cookie-consent-btn {
        font-size: 16px;
        word-spacing: 0px;
        font-weight: lighter;
    }
}

@media (max-width: 768px) {
    .cookie-consent-modal {
        text-align: center;
        display: block;
        padding: 1.5rem 1rem;
        .cookie-consent {
            width: 100%;
            .cookie-consent-heading {
                font-size: 18px;
            }
            .cookie-consent-content {
                font-size: 14px;
                margin-bottom: 1rem;
            }
        }
        .cookie-consent-btn {
            font-size: 14px;
            padding: 0.5rem 1rem;
        }
    }
}
