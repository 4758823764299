.table-scroll {
    position: relative;
    max-width: 930px;
    margin: auto;
    overflow: hidden;
    background-color: #fff;
    padding: 0;
    margin-top: 80px;
    margin-bottom: 80px;
    .table-wrap {
        width: 100%;
        overflow: auto;
    }
    table {
        width: 100%;
        margin: auto;
        border-collapse: separate;
        border-spacing: 0;
        border-spacing: 3px 0px;
        border-collapse: separate;
        td {
            padding: 20px 0px 14px 5px;
            white-space: inherit;
            vertical-align: middle;
            text-align: left;
            font-size: 16px;
            font-weight: 400;
            color: #555;
            span {
                font-size: 11px;
            }
        }
        th {
            padding: 5px 0px 10px 5px;
            white-space: inherit;
            vertical-align: middle;
            text-align: left;
            font-size: 18px;
            font-weight: normal;
            color: #555;
            /* padding-bottom: 20px; */
        }
        tr {
            th {
                &:nth-child(1) {
                    text-align: left;
                    font-weight: 500;
                }
                &:nth-child(2) {
                    background-color: #f0a67a;
                    font-size: 18px;
                    color: #fff;
                    width: 12%;
                    text-align: center;
                    border-radius: 5px 5px 0px 0px;
                    margin: 5px;
                    font-weight: normal;
                    padding: 0;
                }
                &:nth-child(3) {
                    background-color: #a4a4a4;
                    font-size: 18px;
                    color: #fff;
                    width: 12%;
                    text-align: center;
                    border-radius: 5px 5px 0px 0px;
                    margin: 5px;
                    padding: 0;
                }
                &:nth-child(4) {
                    background-color: #d5bb65;
                    font-size: 18px;
                    color: #fff;
                    width: 12%;
                    text-align: center;
                    border-radius: 5px 5px 0px 0px;
                    margin: 5px;
                    padding: 0;
                }
                &:nth-child(5) {
                    background-color: var(--bs-pc-blue);
                    font-size: 18px;
                    color: #fff;
                    width: 12%;
                    text-align: center;
                    border-radius: 5px 5px 0px 0px;
                    margin: 5px;
                    padding: 0;
                }
            }
            td {
                &:nth-child(1) {
                    text-align: left;
                    font-weight: 400;
                    // padding-right: 20px;
                }
                &:nth-child(2) {
                    background-color: #fffaf8;
                    text-align: center;
                    width: 12%;
                }
                &:nth-child(3) {
                    background-color: #f8f8f8;
                    text-align: center;
                    width: 12%;
                }
                &:nth-child(4) {
                    background-color: #fefaf0;
                    color: #434343;
                    font-size: 16px;
                    text-align: center;
                    width: 12%;
                }
                &:nth-child(5) {
                    background-color: #f7f9ff;
                    color: #434343;
                    font-size: 16px;
                    text-align: center;
                    width: 12%;
                }
            }
        }
        &#jalandhar-annual-main-table,
        &#jalandhar-oneTime-main-table {
            thead {
                tr {
                    th {
                        width: 65%;
                        &:nth-child(2) {
                            background-color: #a4a4a4;
                            font-size: 18px;
                            color: #fff;
                            width: 25%;
                            text-align: center;
                            border-radius: 5px 5px 0px 0px;
                            margin: 5px;
                            padding: 0;
                        }
                    }
                }
            }
            td {
                &:nth-child(1) {
                    padding-right: 20px;
                }

                &:nth-child(2) {
                    background-color: #f8f8f8;
                    text-align: center;
                    width: 20%;
                    span {
                        font-size: 11px;
                        display: block;
                    }
                }
            }
        }

        &#ranchi-annual-main-table,
        &#ranchi-oneTime-main-table {
            thead {
                tr {
                    th {
                        width: 65%;
                        &:nth-child(2) {
                            background-color: #a4a4a4;
                            font-size: 18px;
                            color: #fff;
                            width: 25%;
                            text-align: center;
                            border-radius: 5px 5px 0px 0px;
                            margin: 5px;
                            padding: 0;
                        }
                    }
                }
            }
            td {
                &:nth-child(1) {
                    padding-right: 20px;
                }

                &:nth-child(2) {
                    background-color: #f8f8f8;
                    text-align: center;
                    width: 20%;
                    span {
                        font-size: 11px;
                        display: block;
                    }
                }
            }
        }

        &#varanasi-annual-main-table,
        &#varanasi-oneTime-main-table {
            thead {
                tr {
                    th {
                        width: 47%;
                        &:nth-child(2) {
                            background-color: #a4a4a4;
                            font-size: 18px;
                            color: #fff;
                            width: 25%;
                            text-align: center;
                            border-radius: 5px 5px 0px 0px;
                            margin: 5px;
                            padding: 0;
                        }
                        &:nth-child(3) {
                            background-color: #d5bb65;
                            font-size: 18px;
                            color: #fff;
                            width: 25%;
                            text-align: center;
                            border-radius: 5px 5px 0px 0px;
                            margin: 5px;
                            padding: 0;
                        }
                    }
                }
            }
            td {
                &:nth-child(1) {
                    padding-right: 20px;
                }
                &:nth-child(2) {
                    background-color: #f8f8f8;
                    text-align: center;
                    span {
                        font-size: 11px;
                        display: block;
                    }
                }
                &:nth-child(3) {
                    background-color: #fefaf0;
                    text-align: center;
                    span {
                        font-size: 11px;
                        display: block;
                    }
                }
            }
        }
    }
    .clone {
        position: absolute;
        top: 0;
        left: 0px;
        pointer-events: none;
        width: 930px;
        padding: 0;
        max-width: 950px;
        th,
        td {
            visibility: hidden;
            border-color: transparent;
        }
        tbody {
            th {
                visibility: visible;
                color: #555555;
                font-weight: normal;
            }
        }
        .fixed-side {
            background: #fff;
            visibility: visible;
            /* border-right: 1px solid #ccc; */
        }
    }
}

@media only screen and (max-width: 912px) {
    .table-scroll {
        margin-top: 0;
        width: 89%;
    }
}
@media only screen and (max-width: 768px) {
    .table-scroll {
        margin-top: 0;
        width: 92%;
        .clone {
            left: -3px;
        }
    }
    /* .table-scroll table {
        width: 620px;
    } */
}
@media only screen and (max-width: 600px) {
    .table-scroll {
        table {
            &.clone {
                width: 520px;
                left: -9px;
            }
            tr {
                th {
                    &:nth-child(2) {
                        padding: 5px;
                    }
                    &:nth-child(3) {
                        padding: 10px;
                    }
                    &:nth-child(4) {
                        padding: 10px;
                    }
                    &:nth-child(5) {
                        padding: 5px;
                    }
                }
            }
        }
    }
}
@media only screen and (max-width: 480px) {
    .table-scroll {
        table {
            width: 490px;
            &#jalandhar-annual-main-table,
            &#jalandhar-oneTime-main-table {
                width: auto;
            }
            &.clone {
                width: 490px;
            }
        }
    }
}
